<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text>
        <p class="text-center text-xl font-weight-semibold white--text mb-2">
          {{ t('login.enter_phone_email') }}
        </p>
        <p class="ma-0 pt-4 text-center">
          {{ t('login.enter_phone_email_detail') }}
        </p>
      </v-card-text>

      <v-card-text>
        <v-form
          ref="loginForm"
          @submit.prevent="verifySubmit"
        >
          <div
            class="d-flex justify-space-between"
          >
            <v-select
              v-if="isPhoneVisible"
              v-model="logData.phoneCode"
              class="pr-1 w-code"
              dense
              outlined
              rounded
              :items="phoneCodeOptions"
              item-text="text"
              item-value="value"
              :rules="[validators.required]"
              @change="() => {
                loginForm.validate()
              }"
            >
              <template v-slot:item="{ item }">
                <img
                  :src="item.image"
                  :alt="item.value"
                  width="25"
                  height="auto"
                  class="mr-2"
                >
                {{ item.text }}
              </template>
            </v-select>

            <v-text-field
              ref="phoneInput"
              v-model="logData.code"
              class="pl-1"
              :placeholder="isPhoneVisible ? t('users.phone') : t('login.enter_phone_email')"
              dense
              outlined
              rounded
              :rules="isPhoneVisible ? [validators.required, validatePhone] : [validators.required, validators.emailValidator]"
              clearable
              @input="onInputCode"
              @click:clear="onClearCode"
            />
          </div>

          <v-btn
            block
            color="secondary"
            type="submit"
            size="x-large"
            class="mt-6 text-capitalize"
            :disabled="!isFormValid"
          >
            {{ t('tooltip.continue') }}
          </v-btn>
        </v-form>
      </v-card-text>

      <v-card-text>
        <p
          class="mb-2 text-center text-xs"
          style="
            color: #555555 !important
          "
        >
          {{ t('login.enter_detail') }}
        </p>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable no-confusing-arrow */
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import usePhone from '@core/utils/usePhone'
import parsePhoneNumber from 'libphonenumber-js'
import useCountry from '@core/utils/useCountry'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import { verifyUser, sendCodeSMSUser, sendConfirmationEmailUser } from '@api'
import { error } from '@core/utils/toasted'

export default {
  setup() {
    const { router } = useRouter()
    const { t } = useUtils()
    const { phoneCodeOptions, initPhoneConfig } = usePhone()
    const { getCountryCode } = useCountry()
    const { encrypt, decryptData } = useCryptoJs()
    const { logData, currentLogData, updateLogData } = useLogData()

    const loginForm = ref(null)
    const phoneInput = ref(null)

    const initCode = ref('')
    const isPhoneVisible = ref(false)
    const type = computed(() => isPhoneVisible.value ? 'PHONE_NUMBER' : 'EMAIL')

    // const loginData = ref({
    //   code: null, // 'joanrosendo@gmail.com',
    //   exists: null,
    //   fullPhone: null,
    //   joinGame: null,
    //   loginRoute: null,
    //   matchCode: null,
    //   password: null,
    //   phoneCode: null,
    //   phoneNumber: null,
    //   type: null,
    //   typeIsThisYou: null,
    // })

    const isFormValid = computed(() => {
      if (
        (isPhoneVisible.value && logData.value.fullPhone)
        || (!isPhoneVisible.value && logData.value.code && emailValidator(logData.value.code) === true)
      ) return true

      return false
    })

    const verifySubmit = async () => {
      if (loginForm.value.validate()) {
        logData.value.phoneNumber = isPhoneVisible.value ? logData.value.code : ''
        logData.value.type = type.value
        const body = {
          code: isPhoneVisible.value ? logData.value.fullPhone : logData.value.code,
          type: type.value,
        }
        const encryptBody = encrypt(body)

        const resp = await verifyUser(encryptBody)

        if (resp.ok) {
          const decData = decryptData(resp.data)
          logData.value.exists = decData.exists

          updateLogData(logData.value)

          if (decData.exists === 'YES') {
            router.push({ name: 'auth-login' })
          } else {
            let respSend = null
            let info = null
            let encryptInfo = null
            if (isPhoneVisible.value) {
              info = {
                phone_number: logData.value.fullPhone,
              }
              encryptInfo = encrypt(info)
              respSend = await sendCodeSMSUser(encryptInfo)
            } else {
              info = {
                email: logData.value.code,
              }
              encryptInfo = encrypt(info)
              respSend = await sendConfirmationEmailUser(encryptInfo)
            }
            if (respSend.ok) {
              logData.value.phoneCode = initCode.value
              updateLogData(logData.value)

              router.push({ name: 'auth-confirmation-code' })
            } else {
              error(respSend.message.text)
            }
          }
        }
      }
    }

    const onClearCode = () => {
      logData.value.phoneCode = initCode.value
      logData.value.phoneNumber = null
      logData.value.fullPhone = null
      isPhoneVisible.value = false
    }

    const validatePhone = e => {
      if (!/^([0-9])*$/.test(e)) {
        isPhoneVisible.value = false

        return 'Invalid number'
      }

      if (logData.value.phoneCode && e) {
        const phoneNumberJS = parsePhoneNumber(e, logData.value.phoneCode)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            logData.value.fullPhone = phoneNumberJS.number

            return true
          }
          logData.value.fullPhone = null
        }
      } else if (!logData.value.valuePhone && !logData.value.code.includes('+')) {
        onClearCode()
      }

      return 'Invalid number'
    }

    const onInputCode = e => {
      if (!isPhoneVisible.value && e && e !== '' && e.length) {
        if (e.length > 1) {
          if (/^\+?([0-9])+$/.test(e)) {
            logData.value.code = logData.value.code.replace('+', '')
            logData.value.phoneCode = initCode.value
            isPhoneVisible.value = true
          }
        }
      }
    }

    onMounted(async () => {
      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }

      Object.assign(logData.value, currentLogData.value)
      isPhoneVisible.value = logData.value.type === 'PHONE_NUMBER'

      setTimeout(() => {
        phoneInput.value.focus()
      }, 500)
    })

    return {
      loginForm,
      phoneInput,
      logData,
      isPhoneVisible,
      phoneCodeOptions,
      isFormValid,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      verifySubmit,
      validatePhone,
      initPhoneConfig,
      onInputCode,
      onClearCode,

      encrypt,
      decryptData,

      t,

      validators: {
        required,
        emailValidator,
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
